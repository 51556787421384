var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    {
      staticClass: "resources",
      attrs: { color: "#ffffff" },
      on: {
        mouseenter: _vm.disableResizeable,
        mouseleave: _vm.enableResizeable,
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  attrs: { height: "100%", elevation: "0" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "border-bottom": "1px solid #e4e4e4" } },
                    [
                      _c(
                        "v-breadcrumbs",
                        {
                          staticStyle: { padding: "3px 15px" },
                          attrs: { items: _vm.breadCrumbsData },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  item === "fal fa-house"
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "primary--text text-decoration-underline",
                                          staticStyle: {
                                            cursor: "pointer",
                                            "font-size": "14px",
                                          },
                                          attrs: {
                                            tabindex: "0",
                                            role: "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.breadCrumbsClicked(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("homeLabel")))]
                                      )
                                    : _c(
                                        "li",
                                        {
                                          class: _vm.isBreadCrumbsLastData(item)
                                            ? ""
                                            : "primary--text text-decoration-underline",
                                          staticStyle: {
                                            cursor: "pointer",
                                            "font-size": "14px",
                                          },
                                          attrs: {
                                            tabindex: _vm.isBreadCrumbsLastData(
                                              item
                                            )
                                              ? -1
                                              : 0,
                                            role: _vm.isBreadCrumbsLastData(
                                              item
                                            )
                                              ? ""
                                              : "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.breadCrumbsClicked(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm.breadCrumbsData.length === 0
                            ? _c(
                                "li",
                                { staticStyle: { "font-size": "14px" } },
                                [_vm._v(_vm._s(_vm.$t("homeLabel")))]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      ref: "scrollContainer",
                      staticClass: "scroll-container",
                      attrs: { id: "dragLeaveTarget" },
                      on: {
                        drop: function ($event) {
                          $event.preventDefault()
                          return _vm.handleListDrop.apply(null, arguments)
                        },
                        dragenter: _vm.dragenter,
                        dragleave: _vm.dragleave,
                      },
                    },
                    [
                      _c("v-data-table", {
                        ref: "table",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.infiniteScrollList,
                          search: _vm.search,
                          "item-key": "key",
                          height: _vm.$vuetify.breakpoint.smAndDown
                            ? "calc(100vh - 140px)"
                            : _vm.tableHeight,
                          loading: _vm.isTableLoading,
                          "fixed-header": "",
                          "loading-text": _vm.$t("listLoadingMsg"),
                          "show-select": _vm.editMode,
                          "hide-default-footer": "",
                          "disable-pagination": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function ({ items, isSelected, select }) {
                              return [
                                _c(
                                  "tbody",
                                  {
                                    ref: "listContainer",
                                    staticClass: "list-container",
                                  },
                                  [
                                    _vm._l(items, function (item, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: index,
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { tabindex: "0", role: "row" },
                                          on: {
                                            touchstart: function ($event) {
                                              return _vm.handleTouchStart(item)
                                            },
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openPreview({ item })
                                            },
                                            dblclick: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openFolder({ item })
                                            },
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              _vm.notFoldericon(item.key)
                                                ? _vm.openPreview({ item })
                                                : _vm.openFolder({ item })
                                            },
                                          },
                                        },
                                        [
                                          _vm.editMode
                                            ? _c(
                                                "td",
                                                {
                                                  style: _vm.$vuetify.breakpoint
                                                    .smAndDown
                                                    ? "width: 5%"
                                                    : "",
                                                  on: {
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      select(
                                                        item,
                                                        !isSelected(item)
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    staticStyle: {
                                                      margin: "0px",
                                                      padding: "0px",
                                                    },
                                                    attrs: {
                                                      "input-value":
                                                        isSelected(item),
                                                      color: !_vm.$vuetify.theme
                                                        .dark
                                                        ? "#7a7a7a"
                                                        : "#ffffff",
                                                      "hide-details": "",
                                                      "aria-label":
                                                        _vm.setFileName(
                                                          item.key
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        select(
                                                          item,
                                                          !isSelected(item)
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              style: _vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "width: 10%; max-width: 140px;"
                                                : "",
                                            },
                                            [
                                              _vm.isQuickRename &&
                                              item.key ===
                                                _vm.clickQuickRenameFile
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "single-line": "",
                                                          autofocus: "",
                                                          error:
                                                            _vm.errorNewQuickEditFileName,
                                                          "append-icon":
                                                            "fal fa-check",
                                                          maxlength: "225",
                                                        },
                                                        on: {
                                                          blur: _vm.quickRenameBlur,
                                                          "click:append":
                                                            function ($event) {
                                                              return _vm.quickRenameSave(
                                                                _vm.clickQuickRenameFile
                                                              )
                                                            },
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.quickRenameSave(
                                                              _vm.clickQuickRenameFile
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newQuickFileName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.newQuickFileName =
                                                              $$v
                                                          },
                                                          expression:
                                                            "newQuickFileName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _vm.notFoldericon(
                                                        item.key
                                                      )
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                draggable:
                                                                  _vm.editMode,
                                                                color:
                                                                  "#EEEEEE00",
                                                              },
                                                              on: {
                                                                dragstart:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.dragFileName =
                                                                      _vm.setFileName(
                                                                        item.key
                                                                      )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.mimeType(
                                                                        item.mimeType
                                                                      ).color,
                                                                  },
                                                                  on: {
                                                                    drop: _vm.onDrop,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.mimeType(
                                                                        item.mimeType
                                                                      ).icon
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                    disabled:
                                                                      _vm.isToolTipDisabled(
                                                                        _vm.setFileName(
                                                                          item.key
                                                                        )
                                                                      ),
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "span",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "max-width":
                                                                                            "280px",
                                                                                          "white-space":
                                                                                            "nowrap",
                                                                                          "overflow-x":
                                                                                            "hidden",
                                                                                          "text-overflow":
                                                                                            "ellipsis",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          role: "button",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            _vm.quickRename(
                                                                                              item.key,
                                                                                              _vm.setFileName(
                                                                                                item.key
                                                                                              )
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    "span",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.setFileName(
                                                                                        item.key
                                                                                      )
                                                                                    ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.setFileName(
                                                                          item.key
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "hover-edit-icon",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.quickRename(
                                                                          item.key,
                                                                          _vm.setFileName(
                                                                            item.key
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "pl-1",
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fal fa-pencil"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "#EEEEEE00",
                                                              },
                                                              on: {
                                                                drop: function (
                                                                  $event
                                                                ) {
                                                                  _vm.onDrop(
                                                                    _vm.setFileName(
                                                                      item.key
                                                                    )
                                                                  )
                                                                },
                                                                dragover:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.mimeType(
                                                                        item.mimeType
                                                                      ).color,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-folder "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                    disabled:
                                                                      _vm.isToolTipDisabled(
                                                                        _vm.setFileName(
                                                                          item.key
                                                                        )
                                                                      ),
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "span",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "max-width":
                                                                                            "280px",
                                                                                          "white-space":
                                                                                            "nowrap",
                                                                                          "overflow-x":
                                                                                            "hidden",
                                                                                          "text-overflow":
                                                                                            "ellipsis",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          role: "button",
                                                                                        },
                                                                                    },
                                                                                    "span",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.setFileName(
                                                                                        item.key
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.setFileName(
                                                                          item.key
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              style: _vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "width: 10%"
                                                : "",
                                            },
                                            [
                                              item.fileSize > 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.commafy(
                                                          item.fileSize
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              style: _vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "width: 15%"
                                                : "",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.stringifyDate(
                                                      item.fileDate,
                                                      true
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              style: _vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "width: 5%"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-3 hover-button-primary",
                                                      attrs: {
                                                        small: "",
                                                        text: "",
                                                        color: _vm.$vuetify
                                                          .breakpoint.smAndDown
                                                          ? "primary"
                                                          : "transparent",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          _vm.notFoldericon(
                                                            item.key
                                                          )
                                                            ? _vm.editFileName(
                                                                item.key
                                                              )
                                                            : _vm.editFolderName(
                                                                item.key
                                                              )
                                                        },
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("editLabel")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-3 hover-button-error",
                                                      attrs: {
                                                        text: "",
                                                        small: "",
                                                        color: _vm.$vuetify
                                                          .breakpoint.smAndDown
                                                          ? "error"
                                                          : "transparent",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteSingleFilePopup(
                                                            item.key
                                                          )
                                                        },
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "deleteLabel"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    _vm.canLoadMore &&
                                    !_vm.initialLoad &&
                                    (_vm.search === null || _vm.search === "")
                                      ? _c("tr", [
                                          _vm.editMode
                                            ? _c("td", {
                                                style: _vm.$vuetify.breakpoint
                                                  .smAndDown
                                                  ? "width: 5%"
                                                  : "",
                                              })
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            {
                                              style: _vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "width: 21%;"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  style: {
                                                    "margin-right":
                                                      _vm.loadingmarginRight,
                                                  },
                                                  attrs: {
                                                    align: "end",
                                                    justify: "center",
                                                  },
                                                },
                                                [
                                                  _vm.isLoadingMore
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            indeterminate: "",
                                                          },
                                                        }
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "12px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "scrollDownToLoadMoreLabel"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-arrow-down"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("td", {
                                            style: _vm.$vuetify.breakpoint
                                              .smAndDown
                                              ? "width: 10%"
                                              : "",
                                          }),
                                          _c("td", {
                                            style: _vm.$vuetify.breakpoint
                                              .smAndDown
                                              ? "width: 15%"
                                              : "",
                                          }),
                                          _c("td", {
                                            style: _vm.$vuetify.breakpoint
                                              .smAndDown
                                              ? "width: 5%"
                                              : "",
                                          }),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c("div", {
                                  ref: "sentinel",
                                  staticClass: "sentinel",
                                }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedRows,
                          callback: function ($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.isPreviewing
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm.isPreviewing
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto hidden-lg-and-down",
                      attrs: { width: "350", height: "100%", elevation: "0" },
                    },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "pb-btn",
                            {
                              attrs: { icon: "", label: _vm.$t("closeLabel") },
                              on: {
                                click: function ($event) {
                                  return _vm.closeDrawer()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("fal fa-xmark")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isFileImage(_vm.previewObject)
                        ? _c("v-img", {
                            attrs: {
                              height: "270",
                              src: _vm.previewObject.url,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "placeholder",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "fill-height ma-0",
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "text-center pt-3",
                                                },
                                                [
                                                  _c("object", {
                                                    attrs: {
                                                      data: "https://cdn.planbook.com/images/planbook-loading-bump.svg",
                                                      alt: "Planbook Loading",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      staticStyle: {
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("loadingMsg")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1603194162
                            ),
                          })
                        : _vm._e(),
                      _c(
                        "v-card-title",
                        [
                          !_vm.isFileImage(_vm.previewObject)
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "mt-3 mx-auto",
                                  staticStyle: { padding: "70px 90px" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: "100",
                                        color: _vm.mimeType(
                                          _vm.previewObject.mimeType
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mimeType(
                                            _vm.previewObject.mimeType
                                          ).icon
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.isFileImage(_vm.previewObject)
                        ? _c(
                            "v-card-title",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    disabled: _vm.isPreviewToolTipActive(
                                      _vm.previewObject.value
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      "max-width": "400px",
                                                      "white-space": "nowrap",
                                                      "overflow-x": "hidden",
                                                      "text-overflow":
                                                        "ellipsis",
                                                    },
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.previewObject.value
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    654285205
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.previewObject.value)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-card-title",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    disabled: _vm.isPreviewToolTipActive(
                                      _vm.previewObject.value
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-7",
                                                    staticStyle: {
                                                      "max-width": "400px",
                                                      "white-space": "nowrap",
                                                      "overflow-x": "hidden",
                                                      "text-overflow":
                                                        "ellipsis",
                                                    },
                                                  },
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.previewObject.value
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3610534582
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.previewObject.value)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                      _c("v-divider", { staticClass: "mx-4" }),
                      _c(
                        "v-card-text",
                        [
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("actionsLabel"))),
                            ]),
                          ]),
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "#000000de",
                              },
                              attrs: { href: _vm.previewObject.url },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  ref: "downloadButton",
                                  staticClass: "mb-1",
                                  attrs: {
                                    block: "",
                                    depressed: "",
                                    color: !_vm.darkMode
                                      ? "grey lighten-3"
                                      : "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v(" fal fa-download")]
                                  ),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("downloadLabel")) + " "
                                  ),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                block: "",
                                depressed: "",
                                color: !_vm.darkMode ? "grey lighten-3" : "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.editFileName(_vm.previewObject.key)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-3", attrs: { small: "" } },
                                [_vm._v(" fal fa-pencil")]
                              ),
                              _vm._v(" " + _vm._s(_vm.$t("editLabel")) + " "),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                block: "",
                                depressed: "",
                                color: !_vm.darkMode ? "grey lighten-3" : "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteSingleFilePopup(
                                    _vm.previewObject.key
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-3", attrs: { small: "" } },
                                [_vm._v(" fal fa-trash")]
                              ),
                              _vm._v(" " + _vm._s(_vm.$t("deleteLabel")) + " "),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("fileTypeLabel"))),
                          ]),
                        ]),
                        _c("div", [_vm._v(_vm._s(_vm.previewObject.mimeType))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "hidden-xl-only",
                      attrs: {
                        absolute: "",
                        "hide-overlay": "",
                        permanent: _vm.isDrawerVisible,
                        right: "",
                        temporary: "",
                        width: _vm.maxWidthDrawer,
                      },
                      model: {
                        value: _vm.drawer,
                        callback: function ($$v) {
                          _vm.drawer = $$v
                        },
                        expression: "drawer",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "pb-btn",
                            {
                              attrs: { icon: "", label: _vm.$t("closeLabel") },
                              on: {
                                click: function ($event) {
                                  return _vm.closeDrawer()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("fal fa-xmark")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isFileImage(_vm.previewObject)
                        ? _c("v-img", {
                            attrs: {
                              height: "270",
                              src: _vm.previewObject.url,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "placeholder",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "fill-height ma-0",
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "text-center pt-3",
                                                },
                                                [
                                                  _c("object", {
                                                    attrs: {
                                                      data: "https://cdn.planbook.com/images/planbook-loading-bump.svg",
                                                      alt: "Planbook Loading",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      staticStyle: {
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("loadingMsg")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1603194162
                            ),
                          })
                        : _vm._e(),
                      _c(
                        "v-card-title",
                        [
                          !_vm.isFileImage(_vm.previewObject)
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "mt-3 mx-auto",
                                  staticStyle: { padding: "70px 90px" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: "100",
                                        color: _vm.mimeType(
                                          _vm.previewObject.mimeType
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mimeType(
                                            _vm.previewObject.mimeType
                                          ).icon
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.isFileImage(_vm.previewObject)
                        ? _c("v-card-title", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "max-width": "400px",
                                  "white-space": "nowrap",
                                  "overflow-x": "hidden",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [_vm._v(_vm._s(_vm.previewObject.value))]
                            ),
                          ])
                        : _c("v-card-title", [
                            _c(
                              "span",
                              {
                                staticClass: "mt-7",
                                staticStyle: {
                                  "max-width": "400px",
                                  "white-space": "nowrap",
                                  "overflow-x": "hidden",
                                  "text-overflow": "ellipsis",
                                },
                              },
                              [_vm._v(_vm._s(_vm.previewObject.value))]
                            ),
                          ]),
                      _c("v-divider", { staticClass: "mx-4" }),
                      _c(
                        "v-card-text",
                        [
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("actionsLabel"))),
                            ]),
                          ]),
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "none",
                                color: "#000000de",
                              },
                              attrs: { href: _vm.previewObject.url },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  ref: "downloadButton",
                                  staticClass: "mb-1",
                                  attrs: {
                                    block: "",
                                    depressed: "",
                                    color: !_vm.darkMode
                                      ? "grey lighten-3"
                                      : "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v(" fal fa-download")]
                                  ),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("downloadLabel")) + " "
                                  ),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                block: "",
                                depressed: "",
                                color: !_vm.darkMode ? "grey lighten-3" : "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.editFileName(_vm.previewObject.key)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-3", attrs: { small: "" } },
                                [_vm._v(" fal fa-pencil")]
                              ),
                              _vm._v(" " + _vm._s(_vm.$t("editLabel")) + " "),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                block: "",
                                depressed: "",
                                color: !_vm.darkMode ? "grey lighten-3" : "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteSingleFilePopup(
                                    _vm.previewObject.key
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-3", attrs: { small: "" } },
                                [_vm._v(" fal fa-trash-alt")]
                              ),
                              _vm._v(" " + _vm._s(_vm.$t("deleteLabel")) + " "),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("fileTypeLabel"))),
                          ]),
                        ]),
                        _c("div", [_vm._v(_vm._s(_vm.previewObject.mimeType))]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("pb-confirm", { ref: "resourcesConfirm" }),
      _c("v-file-input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "uploadFilesRef",
        attrs: {
          placeholder: _vm.$t("uploadYourDocsLabel"),
          label: _vm.$t("fileInputLabelResources"),
          multiple: "",
          outlined: "",
          "hide-details": "auto",
          "prepend-inner-icon": "mdi-paperclip",
          "prepend-icon": "",
          error: _vm.errorUploadFile,
        },
        on: { change: _vm.changeFileName },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ text }) {
              return [
                _c(
                  "v-chip",
                  { attrs: { small: "", label: "", color: "primary" } },
                  [_vm._v(" " + _vm._s(text) + " ")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.files,
          callback: function ($$v) {
            _vm.files = $$v
          },
          expression: "files",
        },
      }),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 500, expandable: false, withActions: false },
          on: { apply: _vm.CreateFolder },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("createFolderLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                [
                  _vm.isNotEmpty(_vm.breadCrumbsData) && !_vm.showingAllFiles
                    ? _c("v-breadcrumbs", {
                        attrs: { items: _vm.breadCrumbsData },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  item === "fal fa-house"
                                    ? _c(
                                        "span",
                                        { staticClass: "info--text" },
                                        [
                                          item === "fal fa-house"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "info",
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item))]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "info--text" },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1581558280
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          autofocus: "",
                          label: _vm.$t("folderNameLabel") + "*",
                          "aria-required": "true",
                          "prepend-inner-icon": "fal fa-folder",
                          required: "",
                          outlined: "",
                          "hide-details": "auto",
                          error: _vm.errorUploadFolderField,
                          maxlength: "225",
                        },
                        model: {
                          value: _vm.newFolderName,
                          callback: function ($$v) {
                            _vm.newFolderName = $$v
                          },
                          expression: "newFolderName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: false,
            persistent: true,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("fileUploadLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.fileDialog,
            callback: function ($$v) {
              _vm.fileDialog = $$v
            },
            expression: "fileDialog",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                [
                  _vm.isNotEmpty(_vm.breadCrumbsData) && !_vm.showingAllFiles
                    ? _c("v-breadcrumbs", {
                        attrs: { items: _vm.breadCrumbsData },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  item === "fal fa-house"
                                    ? _c(
                                        "span",
                                        { staticClass: "info--text" },
                                        [
                                          item === "fal fa-house"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "info",
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item))]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "info--text" },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1581558280
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                _vm._l(
                  _vm.filesUploadingArray,
                  function (filesUploading, index) {
                    return _c(
                      "v-col",
                      { key: index, attrs: { cols: "12" } },
                      [
                        _c(
                          "v-progress-linear",
                          {
                            attrs: {
                              value: filesUploading.percentage,
                              height: "33",
                              color: "green",
                              rounded: "",
                            },
                          },
                          [
                            [
                              _vm._v(
                                " " + _vm._s(filesUploading.fileName) + "  "
                              ),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(Math.ceil(filesUploading.percentage)) +
                                    "%"
                                ),
                              ]),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 500, expandable: false, withActions: false },
          on: { apply: _vm.confirmEditFile },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("renameLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.renameFileDialog,
            callback: function ($$v) {
              _vm.renameFileDialog = $$v
            },
            expression: "renameFileDialog",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("newNameLabel") + "*",
                          "aria-required": "true",
                          "prepend-inner-icon": "fal fa-file-signature",
                          required: "",
                          outlined: "",
                          "hide-details": "auto",
                          error: _vm.errorNewEditFileName,
                          maxlength: "225",
                        },
                        model: {
                          value: _vm.newEditedFileName,
                          callback: function ($$v) {
                            _vm.newEditedFileName = $$v
                          },
                          expression: "newEditedFileName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { rounded: "pill", color: "primary", timeout: 5000 },
          model: {
            value: _vm.isDraggingFilesOutsidePage,
            callback: function ($$v) {
              _vm.isDraggingFilesOutsidePage = $$v
            },
            expression: "isDraggingFilesOutsidePage",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("dropFilesMessage")))]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm.folderNameDestinationFromOutsidePage ===
                  _vm.$t("homeLabel")
                    ? _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("fal fa-house")]
                      )
                    : _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("fal fa-folder")]
                      ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.folderNameDestinationFromOutsidePage)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }