
















































































































































































































































































































































































































































































































































import { namespace } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import FileServices from '@/services/file-services';
import Confirm from './../../components/core/Confirm.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import { tableWidths, infiniteScrollDelay } from '@/constants/index';

const myFiles = namespace('files');
const settings = namespace('settings');
const index = namespace('index');
var mime = require('mime-types');

@Component({
  mixins: [PageLifeCycleMixin, TableResizeMixin]
})
export default class Resources extends Vue {
  search = '';
  dialog = false;
  fileDialog = false;
  selectingFile = false;
  fileCounterText = '';
  newFolderName = '';
  openedFolderName: any = [];
  openedFolderCounter = 0;
  fileLength = 0;
  isTableLoading = false;
  renameFileDialog = false;
  editingFilename = '';
  newEditedFileName = '';
  errorNewEditFileName = false;
  editingFolderName = '';
  isEditingFolder = false;
  filesUploadingArray: any[] = [];
  deleteFilesDialog = false;
  clickFolderValue = '';
  clickFile = '';
  selectedFile: any = null;
  url = '';
  errorUploadFolderFile = false;
  errorUploadFolderField = false;
  errorUploadFile = false;
  selectedRows: any = [];
  deleteMultipleFilesDialog = false;
  isPreviewing = false;
  isQuickRename = false;
  newQuickFileName = '';
  errorNewQuickEditFileName = false;
  clickQuickRenameFile = '';
  breadCrumbsData:any = [];
  drawer = false;
  isDrawerVisible = true;
  previewObject = {
    mimeType: '',
    fileSize: '',
    fileDate: '',
    url: '',
    value: '',
    key: ''
  };

  lastTapTime = 0;
  doubleTapThreshold = 300;

  dragFileName = '';
  editMode = false;
  infiniteScrollList:any[] = [];
  isLoadingMore = false;
  canLoadMore = true;
  initialLoad = true;
  pageNumber = 0;
  pageCount = 0;
  listEndObserver:any = undefined;
  tableHeight!: any;
  showingAllFiles = false;
  moveMenu = false;
  moveToFolder:any = [];
  moveToOpenedFolder:any = [];
  moveToIsAddingFolder = false;
  moveToNewFolderName = '';
  isDraggingFilesOutsidePage = false;
  folderNameDestinationFromOutsidePage = '';

  headers = [
    {
      text: this.$t('fileNameLabel'),
      align: 'start',
      value: 'value'
    },
    { text: this.$t('fileSizeLabel'), value: 'fileSize', width: tableWidths.mediumText },
    { text: this.$t('lastUpdatedLabel'), value: 'timestamp', width: tableWidths.fullDate },
    {
      width: tableWidths.action2,
      sortable: false,
      value: 'action'
    }
  ];

  $refs!: {
    resourcesConfirm: Confirm,
    listRow:any,
    sentinel: any,
    scrollContainer:any,
    uploadFilesRef: any
  };

  items: any = [];

  files:any[] = [];
  newFiles: any = [];

  @myFiles.State('files')
  myFilesData!: any;

  @myFiles.State('allTeacherFiles')
  myAllFilesData!: any;

  @myFiles.Action
  loadMyFiles!: (params?: any) => Promise<any>;

  @myFiles.Action
  getAllTeacherFiles!: (params?: any) => Promise<any>;

  @myFiles.Action
  getAllFilesInsideFolder!: (params?: any) => Promise<any>;

  @myFiles.Mutation('setFiles')
  setMyFiles!: any;

  @myFiles.Mutation('setHaveFiles')
  setHaveMyFiles!: any;

  @myFiles.Mutation('setHaveAllTeacherFiles')
  setHaveMyAllTeacherFiles!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getCurrentYear')
  currentSchoolYear!: any;

  @settings.Getter('darkMode')
  darkMode!: boolean;

  @index.Action
  disableResizeable!: () => Promise<any>;

  @index.Action
  enableResizeable!: () => Promise<any>;

  @Watch('moveMenu')
  onMoveMenuChanged(val:any) {
    if (!val) {
      this.$nextTick(() => {
        this.moveToFolder = [];
        this.moveToOpenedFolder = [];
        this.moveToIsAddingFolder = false;
        this.moveToNewFolderName = '';
      });
    }
  }

  get localMyFilesData() {
    return this.myFilesData || {};
  }

  get localMyFiles() {
    if (this.showingAllFiles) {
      const data = this.myAllFilesData || [];
      return data.fileList;
    }
    return this.localMyFilesData.fileList || [];
  }

  get localMyFilesMap() {
    const map: any = {};
    this.localMyFiles.forEach((f: any) => {
      map[f.fileKey] = f;
    });
    return map;
  }

  get localMyFilesItems() {
    const that = this;
    this.fileLength = this.localMyFiles.length;
    const folderData: any = [];
    const filesData: any = [];
    const fileData = this.localMyFiles;

    fileData.forEach((s: any) => {
      if (s.fileKey.endsWith('/')) {
        folderData.push({
          key: s.fileKey,
          shortValue: '',
          value: that.setFileName(s.fileKey),
          data: s,
          fileSize: s.fileSize,
          fileDate: s.fileDate,
          mimeType: s.mimeType,
          url: s.fileUrl,
          timestamp: moment(s.fileDate, 'MM/DD/YYYY h:mm A').format('X')
        });
      } else {
        filesData.push(
          {
            key: s.fileKey,
            shortValue: '',
            value: that.setFileName(s.fileKey),
            data: s,
            fileSize: s.fileSize,
            fileDate: s.fileDate,
            mimeType: s.mimeType,
            url: s.fileUrl,
            timestamp: moment(s.fileDate, 'MM/DD/YYYY h:mm A').format('X')
          }
        );
      }
    });
    const finalData = folderData.concat(filesData.sort());
    return finalData;
  }

  get maxWidthTable() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 1700
      case 'sm': return 1700
      case 'md': return 1700
      case 'lg': return 500
      case 'xl': return 1500
    }
  }

  get maxCols() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xl': return 2
    }
  }

  get maxWidthDrawer() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 1000
      case 'sm': return 300
      case 'md': return 400
      case 'lg': return 500
      case 'xl': return 600
    }
  }

  private toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  get loadingmarginRight() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return this.isLoadingMore ? '-55%' : '-100%'
      case 'sm': return this.isLoadingMore ? '-70%' : '-40%'
      case 'md': return this.isLoadingMore ? '-40%' : '-50%'
      case 'lg': return this.isLoadingMore ? '25%' : '15%'
      case 'xl': return this.isLoadingMore ? '23%' : '20%'
    }
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get allFoldersAvailable() {
    const allFiles = this.myAllFilesData.fileList;
    const folders:any = [];
    for (const file of allFiles) {
      const hasFolder = file.fileKey.includes('/');
      if (hasFolder) {
        const splittedKey = file.fileKey.split('/');
        splittedKey.forEach((key:string, index:number) => {
          if (index !== splittedKey.length - 1) {
            const value:any = [];
            splittedKey.every((element:any) => {
              if (key === element) {
                value.push(element);
                return false;
              }
              value.push(element);
              return true;
            });
            const data = {
              value: value.join('/') + '/',
              text: key
            };
            const found = folders.some((e:any) => e.text === key);
            if (!found) {
              folders.push(data);
            }
          }
        });
      }
    }
    return folders;
  }

  get filteredAllAvailableFolders() {
    const that = this;
    const folders:any = [];
    if (CommonUtils.isEmpty(this.moveToOpenedFolder)) {
      this.allFoldersAvailable.forEach((element:any) => {
        if (!element.value) return;
        const splitted = element.value.split('/');
        const found = folders.some((e:any) => e.value === splitted[0] + '/');
        if (!found) {
          folders.push({
            text: element.text,
            value: splitted[0] + '/'
          });
        }
      });
    } else {
      this.allFoldersAvailable.forEach((element:any) => {
        if (!element.value) return;
        const splitted = element.value.split('/');
        const found = folders.some((e:any) => e.text === element.text);
        if (!found) {
          // first check if current element is inside current opened folder
          // second check if current element will not be a duplicate of current opened folder
          // third check if current element is not a child of current folder's child (to make sure to only display current folders direct child)
          if (splitted[that.moveToOpenedFolder.length - 1] === that.moveToCurrentOpenedFolder.text &&
          element.value !== that.moveToCurrentOpenedFolder.value &&
          splitted.length === that.moveToOpenedFolder.length + 2) {
            folders.push({
              text: element.text,
              value: element.value
            });
          }
        }
      });
    }
    return folders;
  }

  get moveToCurrentOpenedFolder() {
    return this.moveToOpenedFolder.slice(-1)[0];
  }

  isBreadCrumbsLastData(item:any):boolean {
    return this.breadCrumbsData[this.breadCrumbsData.length - 1] === item;
  }

  closeDrawer() {
    this.isDrawerVisible = false
    this.drawer = false
    this.isPreviewing = false;
  }

  isFileImage(file:any) {
    if (file.key.toLowerCase().endsWith('.jpg') ||
    file.key.toLowerCase().endsWith('.png') ||
    file.key.toLowerCase().endsWith('.jpeg') ||
    file.mimeType.startsWith('image')) {
      return true;
    } else {
      return false;
    }
  }

  openSubPage(calledByWhat: any) {
    if (calledByWhat === this.$t('createFolderLabel')) {
      this.dialog = true;
      this.errorUploadFolderField = false;
    }
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  saveFile() {
    if (this.newFiles <= 0) {
      this.errorUploadFile = true;
    } else {
      this.initSavingVariables();
      if (this.openedFolderCounter === 0 || this.showingAllFiles) {
        FileServices.uploadFiles(this.newFiles, (file, percent) => {
          this.filesUploadingArray.forEach((element: any) => {
            if (element.fileName === file.name) {
              element.percentage = percent;
            }
          });
        }).then(() => {
          this.isTableLoading = true;
          this.fileDialog = false;
          setTimeout(() => {
            this.refresh();
          }, 2000)
          this.files = [];
          this.newFiles = [];
          this.selectingFile = false;
        });
      } else {
        FileServices.uploadFilesNotRoot(
          this.newFiles,
          this.openedFolderName.join('/'),
          (file, percent) => {
            this.filesUploadingArray.forEach((element: any) => {
              if (element.fileName === file.name) {
                element.percentage = percent;
              }
            });
          }
        ).then(() => {
          this.isTableLoading = true;
          this.fileDialog = false;
          setTimeout(() => {
            this.refresh();
          }, 2000)
          this.files = [];
          this.newFiles = [];
          this.selectingFile = false;
        });
      }
      this.showingAllFiles = false;
    }
  }

  initSavingVariables() {
    this.setHaveMyAllTeacherFiles(false);
    this.errorUploadFile = false;
    this.selectingFile = true;
    this.newFiles.forEach((element: any) => {
      this.filesUploadingArray.push({
        fileName: element.name,
        percentage: 0
      });
    });
  }

  uploadFilesToCurrentYear() {
    FileServices.uploadFilesNotRoot(
      this.newFiles,
      this.currentSchoolYear.yearName,
      (file, percent) => {
        this.filesUploadingArray.forEach((element: any) => {
          if (element.fileName === file.name) {
            element.percentage = percent;
          }
        });
      }
    ).then(() => {
      this.fileDialog = false;
      this.refresh();
      this.files = [];
      this.newFiles = [];
      this.selectingFile = false;
    });
  }

  changeFileName() {
    this.newFiles = [];
    this.files.forEach((element) => {
      const file: any = element;
      if (this.openedFolderName.length > 0) {
        const newFileName = file.name;
        const newFile: any = new File([file], newFileName, { type: file.type });
        this.newFiles.push(newFile);
      } else {
        const newFileName = file.name;
        const newFile: any = new File([file], newFileName, { type: file.type });
        this.newFiles.push(newFile);
      }
    });
    if (CommonUtils.isNotEmpty(this.newFiles)) {
      this.fileDialog = true;
      this.saveFile();
    }
  }

  openFolder(item: any) {
    if (this.showingAllFiles) {
      this.resetOpenedFolder();
    }
    if (this.openedFolderCounter === 0 && !this.notFoldericon(item.item.key)) {
      this.breadCrumbsData.push('fal fa-house');
    }
    if (!this.notFoldericon(item.item.key)) {
      const folderNameArray = item.item.key.split('/');
      const openedFolder = folderNameArray[this.openedFolderCounter];
      this.openedFolderName.push(openedFolder);
      this.breadCrumbsData.push(openedFolder);
      this.openedFolderCounter += 1;
      this.refresh();
    }
  }

  openPreview(item: any) {
    if (item.item.key.endsWith('/')) {
      this.isPreviewing = false;
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': {
          this.drawer = !this.drawer;
          this.isDrawerVisible = true;
          break;
        }
        case 'sm': {
          this.drawer = !this.drawer;
          this.isDrawerVisible = true;
          break;
        }
        case 'md': {
          this.drawer = !this.drawer;
          this.isDrawerVisible = true;
          break;
        }
        case 'lg': {
          this.drawer = !this.drawer;
          this.isDrawerVisible = true;
          break;
        }
      }
      this.isPreviewing = true;
      this.previewObject.mimeType = item.item.mimeType;
      this.previewObject.fileSize = item.item.fileSize;
      this.previewObject.fileDate = item.item.fileDate;
      this.previewObject.url = item.item.url;
      this.previewObject.value = item.item.value;
      this.previewObject.key = item.item.key;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          (this.$refs as any).downloadButton.$el.focus();
        });
      });
    }
  }

  refresh(fetchPlans?: boolean) {
    if (this.openedFolderCounter === 0) {
      this.breadCrumbsData = [];
    }
    this.resetLocalVariables();
    this.setMyFiles({});
    this.localMyFilesData.fileList = [];
    CommonUtils.hideLoading();
    this.isTableLoading = false;
    this.getAllTeacherFiles({ isFolderStructured: false, withAllFolders: true }).then(() => {
      this.initialize(false);
      this.setHaveMyAllTeacherFiles(true);
    });
    if (fetchPlans) {
      this.$store.commit('plans/resetPlans', null, { root: true });
    }
  }

  resetLocalVariables() {
    this.isPreviewing = false;
    this.deleteMultipleFilesDialog = false;
    this.dialog = false;
    this.newFolderName = '';
    if (!this.moveMenu) {
      this.selectedRows = [];
    }
    this.resetInfiniteScrollData();
  }

  resetInfiniteScrollData() {
    this.infiniteScrollList = [];
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    this.isLoadingMore = false;
    this.canLoadMore = true;

    this.initialLoad = true;
    this.pageNumber = 0;
    this.pageCount = 0;
    this.listEndObserver = undefined;
    this.canLoadMore = true;
  }

  mimeType(mimeTypeFromTable: string) {
    this.fileCounterText = '';
    const mimeType = mime.contentType(mimeTypeFromTable);
    const returnedMimeType = CommonUtils.getIconForMimeType(mimeType);
    const iconObj = {
      icon: returnedMimeType.icon,
      color: returnedMimeType.color
    }
    return iconObj;
  }

  CreateFolder() {
    if (this.newFolderName.trim() === '') {
      this.errorUploadFolderField = true;
    } else {
      this.setHaveMyAllTeacherFiles(false);
      this.isTableLoading = true;
      this.errorUploadFolderField = false;
      if (this.openedFolderCounter === 0 || this.showingAllFiles) {
        FileServices.createFolder(this.newFolderName).then(() => this.refresh());
      } else {
        FileServices.createFolder(
          this.openedFolderName.join('/') + '/' + this.newFolderName
        ).then(() => this.refresh());
      }
      this.showingAllFiles = false;
    }
  }

  upOneFolder() {
    if (this.openedFolderCounter !== 0) {
      this.openedFolderName.pop();
      this.openedFolderCounter -= 1;
      this.breadCrumbsData.pop();
      this.refresh();
    }
  }

  breadCrumbsClicked(folderName: string) {
    if (this.isTableLoading) return;
    if (folderName === 'fal fa-house') {
      this.resetOpenedFolder();
      this.search = '';
      this.refresh();
    }
    if (this.openedFolderCounter > 1) {
      let index = this.openedFolderName.indexOf(folderName);
      index = index + 1;
      const howManyToSubtract = this.openedFolderName.length - index;
      this.openedFolderName.splice(index, howManyToSubtract);
      this.openedFolderCounter -= howManyToSubtract;
      this.breadCrumbsData.splice(index + 1, howManyToSubtract);
      this.refresh();
    }
  }

  resetOpenedFolder() {
    this.openedFolderName = [];
    this.breadCrumbsData = [];
    this.openedFolderCounter = 0;
    this.showingAllFiles = false;
  }

  notFoldericon(key: string) {
    if (key.endsWith('/')) {
      return false;
    } else {
      return true;
    }
  }

  setFileName(key: string) {
    const name = key.split('/');
    if (this.showingAllFiles) {
      return name[name.length - 1];
    }
    if (this.openedFolderCounter > 0) {
      return name[this.openedFolderCounter];
    } else {
      return name.join('');
    }
  }

  editFileName(fileName: string) {
    this.renameFileDialog = true;
    this.newEditedFileName = '';
    this.editingFilename = '';
    this.editingFilename = fileName;
    this.isEditingFolder = false;
    this.errorNewEditFileName = false;
    this.newEditedFileName = this.setFileName(fileName).split('.').slice(0, -1).join('.');
  }

  editFolderName(folderName: string) {
    this.renameFileDialog = true;
    this.newEditedFileName = '';
    this.editingFolderName = '';
    this.editingFolderName = folderName;
    this.isEditingFolder = true;
    this.errorNewEditFileName = false;
    this.newEditedFileName = this.setFileName(folderName);
  }

  confirmEditFile() {
    if (this.newEditedFileName.trim() === '') {
      this.errorNewEditFileName = true;
    } else {
      this.setHaveMyAllTeacherFiles(false);
      this.isTableLoading = true;
      if (this.isEditingFolder) {
        this.errorNewEditFileName = false;
        const filteredNewFolderName = this.newEditedFileName
          .split('/')
          .join('');
        if (this.openedFolderCounter > 0) {
          FileServices.editFile(
            this.openedFolderName.join('/') + '/' + filteredNewFolderName + '/',
            this.editingFolderName
          ).then(() => this.refresh(true));
        } else {
          FileServices.editFile(
            filteredNewFolderName + '/',
            this.editingFolderName
          ).then(() => this.refresh(true));
        }
      } else {
        this.errorNewEditFileName = false;
        const extension = this.editingFilename.split('.').pop();
        if (this.openedFolderCounter === 0) {
          const filteredFileName = this.newEditedFileName.split('/').join('');
          FileServices.editFile(
            filteredFileName + '.' + extension,
            this.editingFilename
          ).then(() => this.refresh(true));
        } else {
          const filteredFileName = this.newEditedFileName.split('/').join('');
          const newFileName =
            this.openedFolderName.join('/') +
            '/' +
            filteredFileName +
            '.' +
            extension;
          FileServices.editFile(newFileName, this.editingFilename).then(
            () => this.refresh(true)
          );
        }
      }
      this.newEditedFileName = '';
    }
  }

  deleteFile() {
    this.setHaveMyAllTeacherFiles(false);
    this.isTableLoading = true;
    FileServices.deleteSingleFile(this.clickFile).then(() => this.refresh(true));
    this.deleteFilesDialog = false;
  }

  deleteMultipleFiles() {
    this.setHaveMyAllTeacherFiles(false);
    this.isTableLoading = true;
    FileServices.deleteMultipleFiles(this.selectedRows).then(() => this.refresh(true));
    this.selectedRows = [];
  }

  private async deleteMultipleDialog() {
    const that = this;
    return this.$refs.resourcesConfirm
      .confirm({
        title: this.$t('resourcesLabel'),
        text: this.$t('deleteMultipleFilesText'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      })
      .then((result) => {
        if (result === 1) {
          that.deleteMultipleFiles();
        }
      });
  }

  async deleteSingleFilePopup(fileName: string) {
    this.clickFile = fileName;
    const that = this;
    return this.$refs.resourcesConfirm
      .confirm({
        title: this.$t('resourcesLabel'),
        text: this.$t('deleteFile'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      })
      .then((result) => {
        if (result === 1) {
          that.deleteFile();
        }
      });
  }

  onDrop(folderName: string) {
    this.isTableLoading = true;
    if (this.openedFolderCounter === 0) {
      const newFileName = folderName + '/' +
       this.dragFileName;
      FileServices.editFile(
        newFileName,
        this.dragFileName
      ).then(() => this.refresh(true));
    } else {
      const newFileName =
            this.openedFolderName.join('/') + '/' +
            folderName + '/' +
            this.dragFileName;
      FileServices.editFile(newFileName, this.openedFolderName.join('/') + '/' + this.dragFileName).then(
        () => this.refresh(true)
      );
    }
  }

  quickRename(key: string, filteredKey: string) {
    this.newQuickFileName = filteredKey.split('.').slice(0, -1).join('.');
    this.isQuickRename = true
    this.clickQuickRenameFile = key;
  }

  quickRenameSave(oldKey: string) {
    if (this.newQuickFileName === '') {
      this.errorNewQuickEditFileName = true;
    } else {
      this.errorNewQuickEditFileName = false;
      this.isTableLoading = true;
      const extension = oldKey.split('.').pop();
      if (this.openedFolderCounter === 0) {
        const filteredFileName = this.newQuickFileName.split('/').join('');
        FileServices.editFile(
          filteredFileName + '.' + extension,
          oldKey
        ).then(() => this.refresh(true));
      } else {
        const filteredFileName = this.newQuickFileName.split('/').join('');
        const newFileName =
            this.openedFolderName.join('/') +
            '/' +
            filteredFileName +
            '.' +
            extension;
        FileServices.editFile(newFileName, oldKey).then(
          () => this.refresh(true)
        );
      }
    }
  }

  quickRenameBlur() {
    this.isQuickRename = false;
    this.clickQuickRenameFile = '';
    this.newQuickFileName = '';
    this.errorNewQuickEditFileName = false;
  }

  isToolTipDisabled(fileName: string) {
    if (fileName && fileName.length > 40) {
      return false;
    } else {
      return true;
    }
  }

  isPreviewToolTipActive(fileName: string) {
    if (fileName.length >= 21) {
      return false;
    } else {
      return true;
    }
  }

  initialize(initialLoad:boolean) {
    if (initialLoad) {
      CommonUtils.showLoading();
    } else {
      this.isTableLoading = true;
    }
    const that = this;
    this.renameFileDialog = false;
    this.setHaveMyFiles(false);
    this.setHaveMyAllTeacherFiles(false);
    if (that.openedFolderCounter !== 0) {
      this.loadMyFiles({
        folder: that.openedFolderName.join('/'),
        isFolderStructured: true
      }).then(() => {
        that.setHaveMyFiles(true);
        that.initializeInfiniteScroll();
        CommonUtils.hideLoading();
        this.isTableLoading = false;
      });
    } else {
      this.loadMyFiles({ isFolderStructured: true }).then(() => {
        that.setHaveMyFiles(true);
        that.initializeInfiniteScroll();
        CommonUtils.hideLoading();
        this.isTableLoading = false;
      });
    }
  }

  initializeInfiniteScroll() {
    const that = this;
    if (CommonUtils.isNotEmpty(that.localMyFilesItems)) {
      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;

      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      that.pageCount = Math.round(that.localMyFilesItems.length / itemsPerScreen);
      that.pageNumber = 0;
      that.setInfiniteScrollInitialData();
    } else {
      that.initialLoad = false;
      that.canLoadMore = false;
    }
  }

  setInfiniteScrollInitialData() {
    const that = this;
    this.fetchMoreItems().then((items:any) => {
      this.infiniteScrollList.push(...items);
      this.initialLoad = false;
      // wait for initial list to render and then set up observer
      that.$nextTick(() => {
        this.setUpInterSectionObserver();
      })
    });
  }

  setUpInterSectionObserver() {
    const options = {
      root: this.$refs.scrollContainer,
      margin: '30px'
    };
    this.listEndObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );

    this.listEndObserver.observe(this.$refs.sentinel);
  }

  handleIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }
    if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
      this.loadMore();
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      const items:any = await this.fetchMoreItems();
      console.log('loaded page ' + this.pageNumber);
      this.pageNumber++;
      this.infiniteScrollList.push(...items);
    } catch (error) {
      console.log('Reached end of page', error);
      console.log('total items in the page ' + this.infiniteScrollList.length);
      this.canLoadMore = false;
    } finally {
      this.isLoadingMore = false;
    }
  }

  fetchMoreItems() {
    const that = this;
    const scrollContainer = that.$refs.scrollContainer;
    const tableRowHeight = 48;

    const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
    return new Promise((resolve, reject) => {
      const data:any = [];
      let indexOfLastData = 0;
      if (CommonUtils.isNotEmpty(that.infiniteScrollList)) {
        indexOfLastData = that.localMyFilesItems.findIndex((l:any) => {
          return l.key === that.infiniteScrollList[that.infiniteScrollList.length - 1].key
        });
        indexOfLastData++;
      }
      if ((that.localMyFilesItems.length - indexOfLastData) < itemsPerScreen) {
        for (let index = indexOfLastData; index <= that.localMyFilesItems.length - 1; index++) {
          data.push(that.localMyFilesItems[index]);
        }
      } else {
        for (let i = 1; i <= itemsPerScreen; i++) {
          data.push(that.localMyFilesItems[indexOfLastData]);
          indexOfLastData++;
        }
      }

      setTimeout(() => {
        if (CommonUtils.isNotEmpty(data)) {
          resolve(data);
        } else {
          reject(new Error('No more items to load at the Bottom'));
        }
      }, infiniteScrollDelay);
    });
  }

  created() {
    CommonUtils.showLoading();
    const that = this;
    this.setMyFiles({});
    this.getAllTeacherFiles({ isFolderStructured: false, withAllFolders: true }).then(() => {
      this.initialize(true);
      that.setHaveMyAllTeacherFiles(true);
    });
  }

  destroyed() {
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
  }

  searchForAllTeacherFiles() {
    const that = this;
    this.resetLocalVariables();
    this.isTableLoading = true;
    CommonUtils.showLoading();
    this.breadCrumbsData = ['fal fa-house', this.$t('allFilesLabel')];
    this.getAllTeacherFiles({ isFolderStructured: false, withAllFolders: false }).then(() => {
      this.showingAllFiles = true;
      that.setHaveMyAllTeacherFiles(true);
      that.initializeInfiniteScroll();
      CommonUtils.hideLoading();
      this.isTableLoading = false;
    });
  }

  commafy(num:any) {
    const str = (num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return str;
  }

  openMoveToFolder(item:any) {
    this.moveToOpenedFolder.push(item);
    this.moveToFolder = [];
  }

  moveToCreateNewFolder() {
    if (!this.moveToNewFolderName) return;
    this.setHaveMyAllTeacherFiles(false);
    this.isTableLoading = true;
    this.errorUploadFolderField = false;
    if (CommonUtils.isEmpty(this.moveToOpenedFolder)) {
      FileServices.createFolder(this.moveToNewFolderName).then(() => this.refresh());
    } else {
      FileServices.createFolder(
        this.moveToOpenedFolder.map((element:any) => {
          return element.text;
        }).join('/') + '/' + this.moveToNewFolderName
      ).then(() => this.refresh());
    }
    this.showingAllFiles = false;
    this.moveToIsAddingFolder = false;
    this.moveToNewFolderName = '';
  }

  public async doMove() {
    CommonUtils.showLoading();
    const resp:any = await this.processMove();
    return FileServices.editFiles(resp.newFileNames, resp.oldFileNames).then(() => {
      this.isTableLoading = true;
      this.moveMenu = false;
      this.setHaveMyAllTeacherFiles(false);
      this.refresh(true);
    });
  }

  async processMove() {
    const that = this;
    const newFileNames:any = [];
    const oldFileNames:any = [];
    for (let index = 0; index < that.selectedRows.length; index++) {
      const e = that.selectedRows[index];
      const splitted = e.key.split('/');
      const fileName = splitted.length === 1 ? splitted[0] : splitted[splitted.length - 1];
      if (e.key.endsWith('/')) {
        await that.getAllFilesInsideFolder({
          folder: e.key.slice(0, -1),
          isFolderStructured: false
        }).then((resp:any) => {
          // for folders
          if (CommonUtils.isNotEmpty(that.moveToFolder)) {
            newFileNames.push(that.moveToFolder[0].value + e.value + '/');
          } else {
            // when there is no selected folder to move to
            if (CommonUtils.isEmpty(that.moveToOpenedFolder)) {
              // move in home
              newFileNames.push(e.value + '/');
            } else {
              // move here click inside a folder
              newFileNames.push(that.moveToCurrentOpenedFolder.value + e.value + '/');
            }
          }
          oldFileNames.push(e.key);
          // files inside folders
          const fileList = resp.data.fileList;
          for (const file of fileList) {
            let splittedPath = file.fileKey.split('/');
            const parent = e.key.slice(0, -1).split('/').splice(-1)[0];
            const toRemove:any = [];
            splittedPath.every((element:any) => {
              if (parent !== element) {
                toRemove.push(element);
                return true;
              }
              return false;
            });
            splittedPath = splittedPath.filter((s:any) => {
              if (toRemove.includes(s)) {
                return false
              } else {
                return true;
              }
            })
            const joinedPath = splittedPath.join('/');
            if (CommonUtils.isNotEmpty(that.moveToFolder)) {
              newFileNames.push(that.moveToFolder[0].value + joinedPath);
            } else {
              // when there is no selected folder to move to
              if (CommonUtils.isEmpty(that.moveToOpenedFolder)) {
                // move in home
                newFileNames.push(joinedPath);
              } else {
                // move here click inside a folder
                newFileNames.push(that.moveToCurrentOpenedFolder.value + joinedPath);
              }
            }
            oldFileNames.push(file.fileKey);
          }
        })
      } else {
        // files only
        if (CommonUtils.isNotEmpty(that.moveToFolder)) {
          newFileNames.push(that.moveToFolder[0].value + fileName);
        } else {
          // when there is no selected folder to move to
          if (CommonUtils.isEmpty(that.moveToOpenedFolder)) {
            // move home
            newFileNames.push(fileName);
          } else {
            // move here click inside a folder
            newFileNames.push(that.moveToCurrentOpenedFolder.value + fileName);
          }
        }
        oldFileNames.push(e.key);
      }
    }
    return { newFileNames, oldFileNames }
  }

  uploadFilesOnClick() {
    if (this.$refs.uploadFilesRef) {
      this.$refs.uploadFilesRef.$refs.input.click();
    }
    this.filesUploadingArray = [];
    this.errorUploadFile = false;
  }

  handleTouchStart(item:any) {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - this.lastTapTime;
    this.lastTapTime = currentTime;

    if (timeDifference <= this.doubleTapThreshold) {
      this.openFolder({ item })
    }
  }

  mounted() {
    const events = ['dragenter', 'dragleave', 'dragover', 'drop'];
    events.forEach(event => document.body.addEventListener(event, this.handleEventListener));
  }

  handleListDrop(e: DragEvent) {
    const files:any = e.dataTransfer?.files as FileList;
    if (files.length > 0) {
      this.filesUploadingArray = [];
      if (this.getAssumedFolderNameDestination(e)) {
        this.newFiles = files;
        this.fileDialog = true;
        this.initSavingVariables();
        const suffix = CommonUtils.isEmpty(this.openedFolderName) ? '' : '/';
        const openedFoldeName = this.openedFolderName.join('/') + suffix;
        const folderName = openedFoldeName + this.setFileName(this.getAssumedFolderNameDestination(e).key);
        FileServices.uploadFilesNotRoot(
          this.newFiles,
          folderName,
          (file, percent) => {
            this.filesUploadingArray.forEach((element: any) => {
              if (element.fileName === file.name) {
                element.percentage = percent;
              }
            });
          }
        ).then(() => {
          this.isTableLoading = true;
          this.fileDialog = false;
          setTimeout(() => {
            this.refresh();
          }, 2000)
          this.files = [];
          this.newFiles = [];
          this.selectingFile = false;
        });
      } else {
        this.files = files;
        this.changeFileName();
      }
    }
    this.isDraggingFilesOutsidePage = false;
  }

  beforeDestroy() {
    const events = ['dragenter', 'dragleave', 'dragover', 'drop'];
    events.forEach(event => document.body.removeEventListener(event, this.handleEventListener));
  }

  handleEventListener(e:any) {
    e.preventDefault();
  }

  dragenter(e: DragEvent) {
    const types:any = e.dataTransfer?.types;
    if (types.length > 0 && types.includes('Files')) {
      if (this.getAssumedFolderNameDestination(e)) {
        this.folderNameDestinationFromOutsidePage = this.setFileName(this.getAssumedFolderNameDestination(e).key);
      } else {
        this.folderNameDestinationFromOutsidePage = this.openedFolderCounter === 0 ? this.$t('homeLabel') : this.openedFolderName[this.openedFolderName.length - 1];
      }
      this.isDraggingFilesOutsidePage = true;
    }
  }

  dragleave(e: any) {
    const types:any = e.dataTransfer?.types;
    if (types.length > 0 && types.includes('Files') && e.target.classList.length !== 0) {
      this.isDraggingFilesOutsidePage = false;
      this.folderNameDestinationFromOutsidePage = '';
    }
  }

  getAssumedFolderNameDestination(e:DragEvent) {
    let innerText = (e.target as HTMLElement)?.innerText;
    if (this.openedFolderCounter > 0) {
      innerText = this.openedFolderName.join('/') + '/' + innerText;
    }
    return this.infiniteScrollList.find(file => file.key === innerText + '/');
  }
}
